.FacetList {
}

.Title {
  display: flex;
  justify-content: space-between;

  margin: 24px 0 8px 0;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 150%;
  /* identical to box height, or 18px */

  letter-spacing: 0.13em;
  text-transform: uppercase;

  color: #999999;
}

.itemCount {
  background: rgb(235, 233, 233);
  color: #666;
  border-radius: 4px;
  font-size: 12px;
  padding: 2px 4px;
  margin-left: 8px;
}

.toggleButton {
  background: none;
  border: 0;
  text-decoration: underline;
  font-size: 12px;
}

.footerButton {
  text-align: right;
}

.IconLabel {
  vertical-align: middle;
}
