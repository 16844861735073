.Hero {
  background: url('./hero_image.jpg');
  background-size: cover;
  min-height: 360px;
  text-align: center;

  color: #fff;
}

.container {
  max-width: 1440px;
  margin: auto;
  padding-top: 60px;
}

h1 {
  margin: 0;
}

p {
  display: block;
  margin: auto;
  margin-top: 32px;
  max-width: 1000px;
  font-size: 16px;
  line-height: 1.5;
}

.searchBox {
  background: #fff;
  margin: auto;
  margin-top: 48px;
  max-width: 800px;
  padding: 24px;
  border-radius: 4px;

  box-shadow: 0px 6px 21px rgba(0, 0, 0, 0.42), 0px 2px 6px rgba(0, 0, 0, 0.9),
    0px 1px 2px rgba(0, 0, 0, 0.9);
}

form {
  display: flex;
  align-items: stretch;

  > * {
    flex: 1 1 100px;
  }

  button {
    flex: 0 0 auto;

    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
}
