.Footer {
  margin-top: 48px;
  padding: 24px;
  background: rgb(6, 14, 22);
  color: #fff;
}

.container {
  max-width: 1440px;
  margin: auto;
  display: flex;
  justify-content: space-between;
}

.copyright {
  color: #999;
  font-size: 0.75rem;
}
