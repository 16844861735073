.Main {
}

.container {
  max-width: 1440px;
  margin: 16px auto;
  padding-top: 60px;
  display: flex;
  row-gap: 16px;
  flex-direction: column;
}
