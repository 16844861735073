.CatalogEdit {
}

.catalogRow {
  display: flex;
  align-items: stretch;
  column-gap: 16px;
  margin-bottom: 16px;
}

.typeInput {
  width: 200px;
}

.numberInput {
  flex: 1;
}

.ButtonBar {
  text-align: right;
}
