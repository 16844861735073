.NewMiscForm {
}

.Card,
.Inputs,
.Actions {
  background: #fff;
  border-radius: 2px;
  box-shadow: 0px 4px 10px 2px rgba(0, 0, 0, 0.07);

  padding: 16px;
}

.Inputs {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}

.Actions {
  text-align: right;
}
