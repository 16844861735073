.Topbar {
  background: #fff;
  box-shadow: 0 4px 4px rgba(25, 74, 130, 0.24);
}

.container {
  max-width: 1440px;
  margin: auto;
  display: flex;
  justify-content: space-between;
}

.logoutButton {
  border: 0;
  background: none;
  padding: 12px;
  font-size: 24px;
  height: 48px;
  color: rgba(25, 74, 130, 1);
  cursor: pointer;
}

.name,
.userAvatar {
  display: flex;
  align-items: center;
}

.homeLink {
  text-decoration: none;
  color: #000;
}

.avatar {
  height: 24px;
  border-radius: 100%;
  margin-right: 16px;
}

.userName {
  margin-right: 16px;
}
