.EmptyResults {
  text-align: center;
  padding-bottom: 60px;
}
.IconWrapper {
  border-radius: 2px;
  font-size: 32px;
  color: #174273;
  line-height: 67px;
  background: rgba(23, 64, 114, 0.08);
  width: 60px;
  height: 60px;
  margin: auto;
}
.backLink {
  display: inline;
  border: none;
  background: none;
  text-decoration: underline;
  font: inherit;
  padding: 0;
}
