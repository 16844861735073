.FacetDateInput {
}

.Inputs {
  width: 100%;
  margin-bottom: 16px;
}

.Title {
  margin: 24px 0 8px 0;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 150%;
  /* identical to box height, or 18px */

  letter-spacing: 0.13em;
  text-transform: uppercase;

  color: #999999;
}
