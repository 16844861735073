.container {
  max-width: 1440px;
  margin: auto;
  padding-bottom: 24px;
}

.wrapper {
}

.dropZone {
  color: black;
  background-color: white;

  margin: auto;
  margin-top: 24px;
  max-width: 800px;
  padding: 24px;
  border-radius: 4px;

  box-shadow: 0px 6px 21px rgba(0, 0, 0, 0.42), 0px 2px 6px rgba(0, 0, 0, 0.9),
    0px 1px 2px rgba(0, 0, 0, 0.9);
}
