.Search {
  background: #eef0f5;
  padding-bottom: 24px;
}

.container {
  max-width: 1440px;
  margin: 16px auto;
  padding-top: 60px;
  display: flex;
  column-gap: 24px;
}

.FacetsWrapper {
  flex: 0 0 288px;
}

.ResultsWrapper {
  flex: 1 0;
}

.ResultsControls {
  height: 56px;
  margin-left: 32px;
  display: flex;
  justify-content: space-between;
}

.controlsSelectAction {
  display: flex;
}

.controlsButtonActions {
  display: flex;
  justify-items: end;
  column-gap: 24px;
}

.downloadButtonIcon {
  font-size: 20px;
  margin-right: 8px;
}

.PaginationWrapper {
  display: flex;
  justify-content: center;
}
