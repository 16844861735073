.DetailPageInfo {
  background: #fff;
  border-radius: 2px;
  box-shadow: 0px 4px 10px 2px rgba(0, 0, 0, 0.07);

  padding: 16px;
}

.Section {
  margin: 0 0 8px 0;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 150%;

  letter-spacing: 0.13em;
  text-transform: uppercase;

  color: #999999;

  margin-top: 16px;

  &:first-child {
    margin-top: 0;
  }
}

.prices {
  display: flex;
  justify-content: space-between;

  font-size: 20px;
}

.priceSeparator {
  color: #999;
}
