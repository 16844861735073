.SearchFacets {
  background: #fff;
  border-radius: 2px;
  box-shadow: 0px 4px 10px 2px rgba(0, 0, 0, 0.07);
  margin-bottom: 16px;
  margin-top: 48px;

  padding: 16px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

h2 {
  margin: 0;
}

.resetButton {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;

  &:disabled {
    cursor: default;
  }
}

.submitButton {
  margin-top: 24px !important;
  width: 100%;
}
