.NewLotForm {
}
.Card,
.Inputs,
.Actions {
  background: #fff;
  border-radius: 2px;
  box-shadow: 0px 4px 10px 2px rgba(0, 0, 0, 0.07);

  padding: 16px;
}

.Inputs,
.CurrencyInfo {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}

.IconInputs {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.Actions {
  text-align: right;
}

.CurrencyInfo {
  flex-wrap: wrap;
  flex-direction: row;
  column-gap: 16px;
  height: 56px;
}
.BidInput {
  flex: 4 1 100px;
}
.CurrencyInput {
  flex: 1 1;
}

.soldToggle {
}

.sectionTitle {
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 150%;
  letter-spacing: 0.13em;
  text-transform: uppercase;
  color: #999999;
}
