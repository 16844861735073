.DetailPageEntry {
  display: flex;
  background: #fff;
  border-radius: 2px;
  box-shadow: 0px 4px 10px 2px rgba(0, 0, 0, 0.07);
  margin-bottom: 16px;

  padding: 24px;
}

.Side {
  flex: 0 0 104px;
  text-align: center;
  margin-left: -24px;
  padding: 0 16px;
}

.Year {
  font-weight: 700;
  font-style: italic;
  font-size: 20px;
  color: #ccc;
  margin-top: 4px;
}

.CoverImage {
  max-width: 100%;
  margin-top: 64px;
  margin-bottom: 8px;
}

.MainContent {
  flex: 1 1 100px;
  display: flex;
  flex-flow: row wrap;

  column-gap: 24px;
}

.Header {
  flex: 1 100%;

  display: flex;
  align-items: flex-start;

  justify-content: space-between;
}

.ActionButton {
  visibility: hidden;

  .DetailPageEntry:hover & {
    visibility: visible;
  }
}

.headerSeparator {
  font-size: 16px;
}

.Section,
.fullDate {
  margin: 24px 0 8px 0;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 150%;
  /* identical to box height, or 18px */

  letter-spacing: 0.13em;
  text-transform: uppercase;

  color: #999999;
}
.fullDate {
  margin-top: 4px;
}

.Content {
  flex: 1;
}

.Images {
  flex: 0 250px;
  margin-top: 32px;
  text-align: center;

  img {
    max-width: 100%;
  }
}

.previewImages {
  display: flex;
  column-gap: 16px;
  justify-content: center;
  margin-top: 16px;

  img {
    max-width: 40px;
  }
}

.Table {
  flex: 1 100%;
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 32px;
  column-gap: 16px;
}

.TableColumn {
  flex: 2 1;
}
.TableColumnIcons {
  flex: 1 1;
}

.TableHeader {
  font-weight: 700;
  font-size: 12px;
  color: #999;
  letter-spacing: 0.13em;
  text-transform: uppercase;
  margin-bottom: 16px;
}

.OrgCategories {
  margin-top: 2px;
  color: #999;
  font-style: italic;
}

.Note {
  margin-top: 24px;
}

.PdfLink {
  display: flex;
  align-items: center;
  column-gap: 8px;
  color: inherit;
  text-decoration: none;
  justify-content: center;

  &:hover {
    text-decoration: underline;
  }
}

.Prices {
  flex: 1 100%;
  text-align: right;

  font-weight: 700;
  font-size: 24px;
  margin-top: 24px;
}

.StartingBig {
  font-style: italic;
  font-size: 16px;
  color: #999;
  margin-bottom: 12px;
}
