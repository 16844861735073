.DetailPageView {
  background: #eef0f5;
  padding-bottom: 24px;
}

.container {
  max-width: 1440px;
  margin: 16px auto;
  padding-top: 60px;
  display: flex;
  column-gap: 24px;
}

.sidebar {
  flex: 0 0 288px;

  display: flex;
  flex-direction: column;
  row-gap: 16px;
}

.main {
  flex: 1 0;
}
