.NewView {
  max-width: 800px;
  margin: 16px auto;
  padding-top: 60px;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}

.EntityType,
.Card {
  background: #fff;
  border-radius: 2px;
  box-shadow: 0px 4px 10px 2px rgba(0, 0, 0, 0.07);

  padding: 16px;
}

.EntityType {
  display: flex;
}

.EntityTypeSelect {
  flex: 1;
}

.connectedTo {
  display: flex;
  flex-direction: row;
  column-gap: 16px;
}

.connectedToInput {
  flex: 1 1;
}

.connectedToButton {
  font-size: 16px;
}
