.DetailPageAggregation {
  background: #fff;
  border-radius: 2px;
  box-shadow: 0px 4px 10px 2px rgba(0, 0, 0, 0.07);

  padding: 16px;
}

.Actions {
  display: flex;
  justify-content: center;
  border-top: 1px solid rgba(211, 211, 211, 0.5);

  margin: 0 -16px -8px -16px;
  padding-top: 8px;
}

.Container {
  display: flex;

  flex-wrap: wrap;
  column-gap: 16px;
  row-gap: 16px;
}

.BlockHalf {
  flex: 3;
}

.BlockThird {
  flex: 2;
}

.Section {
  margin: 0 0 8px 0;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 150%;

  letter-spacing: 0.13em;
  text-transform: uppercase;

  color: #999999;
}

.warning {
  color: orange;
  font-size: 18px;
  vertical-align: top;
}
