.BatchEdit {
}

.ModalHeader {
  display: flex;

  align-items: center;
  justify-content: space-between;
  padding: 24px;
  background: #f5f5f5;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}

.ModalTitle {
  font-size: 24px;
  font-weight: bold;
  display: flex;
  align-items: center;
}

.CloseButton {
  background: none;
  border: none;
  font-size: 32px;
  padding: 0;
  color: #999;
  cursor: pointer;
}

.ModalContent {
  padding: 24px;
  line-height: 24px;
}

.ModalActions {
  display: flex;
  gap: 16px;

  align-items: center;
  justify-content: center;
  padding: 24px;
  background: #f5f5f5;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}

.Chip {
  margin-right: 8px;
}
